import axios from "axios";
import history from "router/history";
import { toast } from "react-toastify";
import { handleError } from "utils";
import { setToolbar } from "components/layout/toolbar/actions";
import { clearLogin } from "./login/actions";
import { loadAppStuff } from "components/feature/app/actions";
import { setContest } from "components/feature/contest/actions";
import { placeBet } from "../contest/actions";

export const SET_AUTH = "auth:SET_AUTH";
export const LOGOUT = "auth:LOGOUT";
export const FORGOT_PASSWORD = "auth:FORGOT_PASSWORD";
export const CHANGE_PASSWORD = "auth:CHANGE_PASSWORD";
export const CHANGE_PIN = "auth:CHANGE_PIN";
export const SET_IS_GEOCHECKED = "auth:SET_IS_GEOCHECKED";
export const SET_COOLING_OFF_DURATION = "auth:SET_COOLING_OFF_DURATION";
export const SET_IS_IN_BLOCKED_TERRITORY = "login:SET_IS_IN_BLOCKED_TERRITORY";

export function setAuth(payload) {
  return {
    type: SET_AUTH,
    payload,
  };
}

export function setIsInBlockedTerritory(val) {
  return {
    type: SET_IS_IN_BLOCKED_TERRITORY,
    payload: val,
  };
}

export const logout = () => (dispatch) => {
  axios
    .post(`/auth/logout`)
    .then(({ data }) => {
      localStorage.removeItem("fiver_react_access_token");
      localStorage.removeItem("fiver_react_refresh_token");
      window.xtremepush(
        "tag",
        "logout",
        JSON.stringify({
          AppName: process.env.REACT_APP_TITLE,
        })
      );
      //
      // dispatch(setContest({ picks: {} }));
      // dispatch(clearLogin());
      // dispatch(
      //   setToolbar({
      //     activeBet: undefined,
      //     activePickem: undefined,
      //     activePickemList: undefined,
      //     isActivePickemApproved: undefined,
      //     isActivePickemCompleted: undefined,
      //     isActivePickemInPlay: undefined,
      //     isBetsOpen: undefined,
      //     hadBet: undefined,
      //   })
      // );

      window.location.reload();

      history.push("/");
    })
    .catch(({ response }) => {
      if (response && response.data) {
        if (response.data.code.indexOf("Unauthenticated") > -1) {
          localStorage.removeItem("fiver_react_access_token");
          localStorage.removeItem("fiver_react_refresh_token");
          history.push("/");
        }
      } else {
        handleError(response);
      }
    });
};

export const forgotPassword = (email) => (dispatch) => {
  axios
    .post(`/auth/passwords/forgot`, { email })
    .then(({ data }) => {
      dispatch({
        type: FORGOT_PASSWORD,
        payload: { passwordResetSent: true },
      });

      toast.success("Password Reset Email Sent");
      //history.push("/");
    })
    .catch(({ response }) => {
      handleError(response);
    });
};

export const changePassword = (params) => (dispatch) => {
  axios
    .put(`/auth/password`, params)
    .then(({ data }) => {
      toast.success(`Password Changed`);
      history.push("/profile");
      dispatch({
        type: CHANGE_PASSWORD,
        payload: undefined,
      });
    })
    .catch(({ response }) => {
      dispatch({
        type: CHANGE_PASSWORD,
        payload: response.data.message,
      });
    });
};

export function clearPinError() {
  return {
    type: CHANGE_PIN,
    payload: undefined,
  };
}

export const changePin = (params) => (dispatch) => {
  axios
    .put(`/auth/pin`, params)
    .then(({ data }) => {
      toast.success(`Pin Changed`);
      history.push("/profile");
      dispatch({
        type: CHANGE_PIN,
        payload: undefined,
      });
    })
    .catch(({ response }) => {
      dispatch({
        type: CHANGE_PIN,
        payload: response.data.message,
      });
    });
};

export const verifyEmail = (params) => (dispatch) => {
  axios
    .get(
      `/auth/verification/13?expires=1563463179&signature=6537f76b23a175fc1973a741ed7a200746ac048076b14b4e3d3196310ceff005`
    )
    .then(({ data }) => {
      toast.success(`Email Verified`);
    })
    .catch(({ error }) => {
      handleError(error);
    });
};
export const verifyReferral = (params) => (dispatch) => {
  //Need Api Endpoint here for verification using Email placeholder
  axios
    .get(
      `/auth/verification/13?expires=1563463179&signature=6537f76b23a175fc1973a741ed7a200746ac048076b14b4e3d3196310ceff005`
    )
    .then(({ data }) => {
      toast.success(`Referral Verified`);
    })
    .catch(({ error }) => {
      handleError(error);
    });
};
export const verifyPhoneNumber = (params, isSignup, userBetData) => (dispatch) => {
  axios
    .post(`/verification/mobile/verify`, params)
    .then(({ data }) => {
      dispatch(loadAppStuff());

      toast.success(`Phone Number Verified`);
      window.xtremepush(
        "tag",
        "Registration.VerifyMobile",
        JSON.stringify({
          AppName: process.env.REACT_APP_TITLE,
        })
      );
      if (isSignup) {
        history.push("/");

        if (userBetData) {
          dispatch(placeBet(userBetData?.activePickem?.id, userBetData?.picks, userBetData?.activePickem?.name, userBetData?.tiebreaker))
        }
      } else {
        history.goBack();
      }
    })
    .catch(({ error, ...rest }) => {
      if (rest?.response?.data?.message) {
        toast.error(rest.response.data.message);
      } else {
        handleError(error);
      }
    });
};

export const getCoolingOffDuration = () => (dispatch) => {
  axios
    .get(`/cooling-off`)
    .then(({ data }) => {
      toast.success(`Phone Number Verified`);
      dispatch({
        type: SET_COOLING_OFF_DURATION,
        payload: { coolingOffData: data.data },
      });
    })
    .catch(({ response }) => {
      handleError(response);
    });
};

export const setCoolingOffDuration = (params) => (dispatch, getState) => {
  axios
    .post(`/cooling-off`, params)
    .then(({ data }) => {
      toast.success(
        `You took a break for ${params.duration} ${
          params.duration > 1 ? "days" : "day"
        }`
      );
      dispatch({
        type: SET_COOLING_OFF_DURATION,
        payload: {
          coolingOffData: {
            ...getState().auth.coolingOffData,
            duration: params.duration,
            expiresAt: data.data.expiresAt
          },
        },
      });
    })
    .catch(({ error, ...rest }) => {
      if (rest?.response?.data?.message) {
        toast.error(rest.response.data.message);
      } else {
        handleError(error);
      }
    });
};

export const setSelfExclusionDuration = (params) => (dispatch) => {
  axios
    .post(`/self-exclusion`, params)
    .then(({ data }) => {
      dispatch(logout());
      toast.success(
        `You are self excluded for ${
          params.duration === 6 ? 6 : params.duration / 12
        } ${
          params.duration === 6
            ? "months"
            : params.duration / 12 > 1
            ? "years"
            : "year"
        }`
      );
    })
    .catch(({ error, ...rest }) => {
      if (rest?.response?.data?.message) {
        toast.error(rest.response.data.message);
      } else {
        handleError(error);
      }
    });
};

export const sendVerificationCode = () => () => {
  axios
    .post(`/verification/mobile/send`)
    .then(({ data }) => {
      //toast.success("Code sent. It may take up to 2 minutes to receive.");
      toast.success("Code sent. Please check your phone.");
    })
    .catch(({ error }) => {
      handleError(error);
    });
};

export function setIsGeoChecked(value) {
  return {
    type: SET_IS_GEOCHECKED,
    payload: value,
  };
}
