import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  Form,
  FormRow,
  FormRowMessage,
  FormTextbox,
  BlockButton,
  Card,
  Alert,
} from "components/shared";

import { setAuth, sendVerificationCode, verifyPhoneNumber } from "./actions";

const StyledText = styled.div`
  margin-bottom: 10px;
`;

const ConfirmPhoneNumber = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState("");
  const { mobileNumber } = useSelector((state) => state.app);
  const location = useLocation();

  const { userBetData } = useSelector((state) => state.auth);

  const isFromSignupPage = location?.search.indexOf("signup") > -1;

  useEffect(() => {
    window.xtremepush(
      "tag",
      "registration_mobile_verification",
      JSON.stringify({
        AppName: process.env.REACT_APP_TITLE,
      })
    );
  }, []);

  useEffect(() => {
    if (localStorage.getItem("fiver_react_access_token")) {
      if (!isFromSignupPage) {
        dispatch(sendVerificationCode());
      }
    }
  }, []);

  const showConsentPopup = () => {
    dispatch(
      setAuth({
        isShowingConsentPopup: true,
      })
    );
  };

  const handleTextboxChanged = (e) => {
    setState(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (process.env.REACT_APP_HAS_CONSENT_POPUP === "true") {
      showConsentPopup();
    }

    dispatch(verifyPhoneNumber({ code: state }, isFromSignupPage, userBetData));
  };

  const handleResendCode = (e) => {
    e.preventDefault();
    dispatch(sendVerificationCode());
  };

  const handleClose = (e) => {
    e.preventDefault();

    if (process.env.REACT_APP_HAS_CONSENT_POPUP === "true") {
      showConsentPopup();
    }

    if (isFromSignupPage) {
      history.push("/");
    } else {
      history.push("/profile");
    }
  };

  return (
    <Card>
      <Alert margin="0 0 1rem">
        Please enter the 6-digit code that has been sent to {mobileNumber}
      </Alert>
      <Form>
        <FormRow isSmall>
          <FormTextbox
            isSmall
            // isError={}
            onChange={handleTextboxChanged}
            name="code"
            type="number"
            placeholder="Enter received code"
          />
        </FormRow>
        <FormRowMessage isVisible={state && state.length !== 6}>
          Code must be 6 characters
        </FormRowMessage>

        <BlockButton
          disabled={state.length !== 6}
          onClick={handleSubmit}
          style={{
            marginBottom: "1rem",
          }}
        >
          VERIFY
        </BlockButton>
        <BlockButton
          onClick={handleResendCode}
          style={{ marginBottom: "1rem" }}
        >
          NO CODE? TAP TO RESEND
        </BlockButton>
        {!isFromSignupPage &&
          <BlockButton style={{ marginBottom: "1rem" }} onClick={handleClose}>
            CANCEL
          </BlockButton>}
        <Alert isDanger>
          {"WARNING: You need to have your phone number verified to make your "}
          {process.env.REACT_APP_HIDE_GAMBLING_REFERENCES === "true"
            ? process.env.REACT_APP_TEXT_PICKS || "picks."
            : "picks and place bets."}
        </Alert>
      </Form>
    </Card>
  );
};

export default ConfirmPhoneNumber;
