import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";

import styled from "styled-components";
import {
  Form,
  FormRow,
  FormRowMessage,
  FormTextbox,
  TextButton,
  BlockButton,
  AppIcon,
  Card,
} from "components/shared";
import { checkLogin, updateGdpr, clearLogin } from "./actions";
import { useCheckLogin } from "hooks";

import LoginGdpr from "./LoginGdpr";

const StyledCard = styled(Card)`
  border-radius: ${(p) => p.theme.borderRadius};
`;

const StyledLogoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
`;

const StyledBackButton = styled(BlockButton)`
  background: ${(p) => p.theme.colorSucces};
  margin-top: 1rem;
`;

const Login = (props) => {
  const {
    checkLogin,
    clearLogin,
    isLoggingIn,
    loginSuccess,
    loginError,
    updateGdpr,
  } = props;

  const { location } = useSelector((state) => state.auth);
  const [state, setState] = useState({
    username: "",
    password: "",
  });

  const { gdprAccepted } = useSelector((state) => state.login);

  const { userBetData } = useSelector((state) => state.auth);

  const history = useHistory()

  useCheckLogin();

  useEffect(() => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      setState({
        username: "joeharrison",
        password: "Solihull1",
      });
    }
    return () => clearLogin();
  }, [setState, clearLogin]);

  const formIsValid = state.username.length > 0 && state.password.length > 0;

  const handleTextboxChanged = (e) => {
    const target = e.target;
    setState({
      ...state,
      [target.name]: target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    checkLogin(state.username, state.password, userBetData);
  };

  return (
    <>
      <LoginGdpr updateGdpr={updateGdpr} isVisible={gdprAccepted === false} />
      <StyledCard>
        <Form>
          <StyledLogoRow>
            <AppIcon size="120px" />
          </StyledLogoRow>
          <FormRow>
            <FormTextbox
              onChange={handleTextboxChanged}
              name="username"
              value={state.username}
              placeholder="Username / Email"
            />
          </FormRow>
          <FormRow>
            <FormTextbox
              onChange={handleTextboxChanged}
              name="password"
              value={state.password}
              type="password"
              placeholder="Password"
            />
          </FormRow>
          <FormRowMessage isVisible={loginSuccess === false}>
            {loginError}
          </FormRowMessage>
          <FormRow style={{ padding: "20px 0" }}>
            <TextButton to="/forgot">Forgot Password?</TextButton>
          </FormRow>
          <BlockButton
            isLoading={isLoggingIn}
            disabled={!formIsValid || isLoggingIn}
            onClick={handleSubmit}
          >
            Login
          </BlockButton>
          <StyledBackButton
            isLoading={isLoggingIn}
            disabled={!formIsValid || isLoggingIn}
            onClick={() => history.push("/")}
          >
            Back
          </StyledBackButton>
        </Form>
      </StyledCard>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  isLoggingIn: state.login.isLoggingIn,
  loginSuccess: state.login.loginSuccess,
  loginError: state.login.loginError,
});

const mapActionsToProps = {
  checkLogin,
  updateGdpr,
  clearLogin,
};

export default connect(mapStateToProps, mapActionsToProps)(Login);
