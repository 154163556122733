import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import { rgba } from "polished";
import { MatIcon, LogoBadge } from "components/shared";
import TopbarBurger from "./TopbarBurger";
import { logout } from "components/feature/auth/actions";
import { getPage } from "../drawer/actions";
import { createImageCdnLink } from "@low6dev/react-utils/dist";

const StyledWrapper = styled.div`
  position: fixed;
  z-index: 8;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${(p) => p.theme.topbarHeight};
  color: ${(p) => p.theme.colorPrimaryText};
  background: ${(p) =>
    process.env.REACT_APP_COLOR_TOPBAR || p.theme.colorPrimary};

  ${(p) =>
    process.env.REACT_APP_COLOR_PRIMARY_IS_WHITE &&
    `border-bottom: 2px solid ${p.theme.colorBorder}`};

  @media (min-width: ${(p) => p.theme.screenWidthMd}) {
    left: 50%;
    width: ${(p) => p.theme.railWidth};
    transform: translateY(${(p) => (p.isVisible ? p.theme.topbarHeight : "0")})
      translateX(-50%);
  }
`;

const StyledIconButton = styled.button`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: ${(p) => p.buttonWidth || "72px"};
  height: ${(p) => p.theme.topbarHeight};
  color: ${(p) => p.theme.colorPrimaryText};

  &:disabled {
    color: ${(p) => rgba(p.theme.colorPrimaryText, 0.25)};
  }
`;

const StyledPageTitle = styled.div`
  flex: 1;
  text-align: center;
`;

const StyledBurgerReplacement = styled.img`
  height: ${(p) => p.theme.topbarHeight};
  margin-left: 1rem;
`;

const StyledAuthButton = styled.button`
  padding: 0.745rem 1.2rem;;
  margin-left: 1rem;
  color: ${(p) => p.theme.colorPrimaryText};
  background: ${(p) => p.theme.colorSuccess};
  border-radius: 2rem;
`;

const Topbar = ({
  logout,
  getPage,
  topbarBackButton,
  topbarBackButtonText,
  topbarBurgerMenu,
  topbarTitleLogo,
  topbarTitle,
  buttonWidth,
  showOptaWidget,
  isUserAuthed
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [pageName, setPageName] = useState("Login");

  let history = useHistory();

  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === "/login") {
      setPageName('Signup')
    } else if (pathname === "/signup" || pathname === "/") {
      setPageName('Login')
    }
  }, [pathname])

  const handlePage = () => {
    if (pageName === "Login") {
      history.push("/login")
    } else if (pageName === "Signup") {
      history.push("/signup")
    }
  }

  const NormalTopBar = () => {
    return (
      <>
        {process.env.REACT_APP_HIDE_BURGER_MENU !== "true" && (
          <TopbarBurger
            logout={logout}
            toggleIsOpen={setIsOpen}
            isOpen={isOpen}
          />
        )}
        <StyledWrapper>
          {!topbarBackButton && !topbarBurgerMenu && (
            <StyledIconButton disabled>
              <MatIcon>chevron_left</MatIcon>
              Back
            </StyledIconButton>
          )}
          {topbarBackButton && (
            <StyledIconButton
              buttonWidth={buttonWidth}
              onClick={() => history.goBack()}
            >
              <MatIcon>chevron_left</MatIcon>
              {topbarBackButtonText || "Back"}
            </StyledIconButton>
          )}
          {topbarBurgerMenu && process.env.REACT_APP_HIDE_BURGER_MENU !== "true" && (
            <StyledIconButton
              isBurger
              buttonWidth={buttonWidth}
              onClick={() => setIsOpen(!isOpen)}
            >
              <MatIcon size="40px" style={{ zIndex: 2 }}>
                menu
              </MatIcon>
            </StyledIconButton>
          )}
          {process.env.REACT_APP_HIDE_BURGER_MENU === "true" &&
            topbarBurgerMenu && (
              <StyledBurgerReplacement
                src={createImageCdnLink(
                  process.env.REACT_APP_HIDE_BURGER_MENU_REPLACEMENT_LOGO
                )}
              />
            )}
          {topbarTitleLogo && <LogoBadge height="56px" width="auto" />}
          {topbarTitle && <StyledPageTitle>{topbarTitle}</StyledPageTitle>}
          <StyledIconButton
            buttonWidth={buttonWidth}
            onClick={() =>
              getPage(process.env.REACT_APP_HELP_SLUG || "help-fiver", "Help")
            }
          >
            <MatIcon size="30px">help_outline</MatIcon>
          </StyledIconButton>
        </StyledWrapper>
      </>
    )
  }

  const UnAuthedTopBar = () => {
    return (
      <>
        <StyledWrapper>
          <StyledAuthButton onClick={() => handlePage()}>{pageName}</StyledAuthButton>
          {topbarTitleLogo && <LogoBadge height="56px" width="auto" />}
          {topbarTitle && <StyledPageTitle>{topbarTitle}</StyledPageTitle>}
          <StyledIconButton
            buttonWidth={buttonWidth}
            onClick={() =>
              getPage(process.env.REACT_APP_HELP_SLUG || "help-fiver", "Help")
            }
          >
            <MatIcon size="30px">help_outline</MatIcon>
          </StyledIconButton>
        </StyledWrapper>
      </>
    )
  }


  return (
    isUserAuthed ? <NormalTopBar /> : <UnAuthedTopBar />
  );
};

const mapStateTop = (state) => ({});

const mapActionsTop = {
  logout,
  getPage,
};

export default connect(mapStateTop, mapActionsTop)(Topbar);
