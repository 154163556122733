import styled, { css } from "styled-components";
import { useState } from "react";
import { useSelector } from "react-redux";
import { createImageCdnLink } from "utils";
import { MatIcon } from "fiver-react-ui-components";

const visibleStyles = css`
  visibility: visible;
  opacity: 1;
`;

const StyledWrapper = styled.div`
  position: fixed;
  z-index: 10;
  left: 50%;
  bottom: 80px;
  width: 100%;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s linear;

  @media (min-width: ${(p) => p.theme.screenWidthXxs}) {
    width: 300px;
  }

  ${(p) => p.isVisible && visibleStyles};
`;

const StyledCloseButton = styled.button`
  position: absolute;
  top: -45px;
  right: 0;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background: #0d0d0d;
  color: #f9f9f9;
  display: grid;
  padding: 0;
  place-items: center;
`;

const StyledLink = styled.a`
  position: relative;
  display: block;
  width: 100%;
  height: 80px;
`;

const StyledImageWrapper = styled.span`
  position: absolute;
  right: 1rem;
  bottom: 1rem;
`;

const StyledImage = styled.img`
  width: 250px;
`;

const StyledIframeWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 260px;
  right: -20px;
  bottom: 1rem;


  @media (min-width: ${(p) => p.theme.screenWidthXxs}) {
    width: auto;
  }

  @media (max-width: ${(p) => p.theme.screenWidthXxs}) {
    width: auto;
  }
`;

const StyledIframe = styled.iframe`
  cursor: auto;
  border: 0;
  height: 100%;
`;

const StyledIframeLink = styled.a`
  display: flex;
  cursor: pointer;
  height: 100%;
`;

const StyledIframeCloseButton = styled.button`
  position: absolute;
  top: -45px;
  right: 40px;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background: #0d0d0d;
  color: #f9f9f9;
  display: grid;
  padding: 0;
  place-items: center;
`;

const StyledBlock = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: transparent;
  z-index: 1;
`;

const Wrapper = ({ ctaUrl, imageUrl, isIframed }) => {
  const { showSubmissionPopup } = useSelector((s) => s.app);
  const { activeBet } = useSelector((s) => s.toolbar);

  const [isDismissed, setDismissed] = useState(false);

  const handleCloseClicked = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setDismissed(true);
  };

  const openLink = (url) => {
    let popupType = "Activebet";

    if (showSubmissionPopup === true) {
      popupType = "PickSubmission";
    }

    window.xtremepush("tag", `AffiliatePopup.Click.${popupType}`,
      JSON.stringify({
        AppName: process.env.REACT_APP_TITLE
      }));

    window.open(url, '_blank').focus()
  }

  const ScrollingPopup = () => {
    return (
      <StyledWrapper isVisible={!isDismissed}>
        {isIframed ? (
          <StyledIframeWrapper>
            <StyledIframeCloseButton onClick={handleCloseClicked}>
              <MatIcon>close</MatIcon>
            </StyledIframeCloseButton>
            <StyledBlock>
              <StyledIframeLink onClick={() => openLink(ctaUrl)} />
            </StyledBlock>
            <StyledIframe
              srcDoc={`<script type="text/javascript" src=${isIframed}></script><style> body { margin: 0;overflow: hidden;height: auto;width: auto;transform: scale(0.325) translate(0%,0%);-ms-transform-origin: 0% 0%;transform-origin: 0% 0%; } iframe { border: 0; }</style>`}></StyledIframe>
          </StyledIframeWrapper>
        ) : (ctaUrl ? (
          <StyledLink onClick={() => openLink(ctaUrl)}>
            <StyledImageWrapper>
              <StyledCloseButton onClick={handleCloseClicked}>
                <MatIcon>close</MatIcon>
              </StyledCloseButton>
              <StyledImage src={createImageCdnLink(imageUrl)} />
            </StyledImageWrapper>
          </StyledLink>
        ) : (
          <StyledImageWrapper>
            <StyledCloseButton onClick={handleCloseClicked}>
              <MatIcon>close</MatIcon>
            </StyledCloseButton>
            <StyledImage src={createImageCdnLink(imageUrl)} />
          </StyledImageWrapper>
        ))}
      </StyledWrapper>
    )
  }

  return (
    <>
      {((activeBet && imageUrl !== undefined) || showSubmissionPopup) ? <ScrollingPopup /> : null}
    </>
  );
};

export default Wrapper;
