import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import history from "router/history";
import { toast } from "react-toastify";
import { Profile as AppProfile } from "@low6dev/react-feature-profile/dist";
import { handleError, createImageCdnLink } from "@low6dev/react-utils/dist";
import { loadAppStuff } from "components/feature/app/actions";
import { getPage } from "components/layout/drawer/actions";

const StyledWrapper = styled.section`
  padding: 1rem 0;
`;

const Profile = ({ setBetbarParent, betbarParent }) => {
  const dispatch = useDispatch();

  const {
    isScrolledDown,
    isNonUkUser,
    isDevEnvironment,
    tenantDetails,
  } = useSelector((state) => state.app);

  const changeRoute = (path) => {
    history.push(`/${path}`);
  };

  const handleLogoutSuccess = () => {
    localStorage.removeItem("fiver_react_access_token");
    localStorage.removeItem("fiver_react_refresh_token");
    window.location.href = "/";
  };

  const handleProfileSaveSuccess = (profile) => {
    dispatch(loadAppStuff(profile));
  };

  const handleProfileError = (error) => {
    console.log("handleProfileError", error);
    // history.push("/");
    handleError(error);
  };

  const handlePageButtonClicked = (page, pageTitle, useAssetsCdn) => {
    dispatch(getPage(page, pageTitle, useAssetsCdn));
  };

  return (
    <StyledWrapper>
      <AppProfile
        hideLow6Button={
          process.env.REACT_APP_HIDE_PROFILE_HIDE_LOW6_BUTTON === "true"
        }
        hideOpenWalletButton={
          process.env.REACT_APP_HIDE_PROFILE_HIDE_OPEN_WALLET_BUTTON === "true"
        }
        hideGamblingReferences={
          process.env.REACT_APP_HIDE_GAMBLING_REFERENCES === "true" ||
          process.env.REACT_APP_PROFILE_HIDE_LOW6_HELP === "true"
        }
        hideChangePin
        hideLogoutButton={
          process.env.REACT_APP_IS_PROFILE_LOGOUT_BUTTON_VISIBLE !== "true" &&
          process.env.REACT_APP_HIDE_BURGER_MENU !== "true"
        }
        hideEmailField={false}
        hidePushNotifcationCheckbox={
          process.env.REACT_APP_SIGNUP_HIDE_PUSH_NOTIFICATIONS_CHECKBOX ===
          "true"
        }
        hideCheckAllCheckbox={process.env.REACT_APP_IS_BLENHEIM === "true"}
        hideVerifyButton={
          process.env.REACT_APP_IS_MOBILE_VERIFICATION_DISABLED === "true"
        }
        isFiver
        isMinimalAddress={false}
        isNonUkUser={isNonUkUser}
        isScrolledDown={isScrolledDown}
        onLogoutSuccess={handleLogoutSuccess}
        onProfileSaveSuccess={handleProfileSaveSuccess}
        onVerifyMobileClicked={() => changeRoute("confirm-phone")}
        onChangePasswordClicked={() => changeRoute("change-password")}
        onError={handleProfileError}
        ctaUrl={process.env.REACT_APP_CTA_URL}
        ctaIconUrl={
          process.env.REACT_APP_CTA_ICON
            ? createImageCdnLink(process.env.REACT_APP_CTA_ICON)
            : undefined
        }
        ctaText={process.env.REACT_APP_CTA_TEXT}
        tenantDetails={tenantDetails}
        privacySlug="privacy"
        onPageButtonClicked={handlePageButtonClicked}
        forcedCountryCode={process.env.REACT_APP_FORCED_COUNTRY_CODE}
      />
    </StyledWrapper>
  );
};

export default Profile;
