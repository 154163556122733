import { useSelector, useDispatch } from "react-redux";
import styled, { keyframes } from "styled-components";
import { rgba } from "polished";
import { FootballMatch, MoneyText } from "components/shared";
import { DateTime, MatIcon, Skeleton } from "fiver-react-ui-components";
import { setToolbar } from "./actions";
import { IconBlock } from "./middle/shared";

import ToolbarUpperPrize from "./ToolbarUpperPrize";

const endCapWidth = "108px";

const StyledTopRow = styled.button`
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  width: 100%;
  padding: 0 0.5rem;
`;

const StyledEnd = styled.div`
  flex: 0 1 ${endCapWidth};
  max-width: ${(p) => endCapWidth};
  width: ${(p) => endCapWidth};
  min-width: ${(p) => endCapWidth};
  display: flex;
  flex-direction: column;
  align-items: ${(p) => (p.isRight ? "flex-end" : null)};
  justify-content: center;
`;

const pulse = (p) => keyframes`
  0% { box-shadow: 0 0 0 0 ${rgba(p.theme.colorDark, 0.5)}; }
  33% { box-shadow: 0 0 0 3px ${rgba(p.theme.colorDark, 0)}; }
  100% { box-shadow: 0 0 0 3px ${rgba(p.theme.colorDark, 0)}; }
`;

const StyledArrowIcon = styled(MatIcon)`
  font-size: 20px;
  transform: rotate(${(p) => (!p.isBetsOpen ? "0" : "-180deg")})
    translateY(-1px);
  transition: transform 0.35s;
`;

const StyledDropdownButton = styled.button`
  padding: 0 0.25rem 0 0.5rem;
  border-radius: ${(p) => p.theme.borderRadius};
  font-size: 0.75rem;
  height: 32px;
  white-space: nowrap;
  width: 100%;
  background: ${(p) => p.theme.colorLightGrey};
  background-clip: border-box;
  display: flex;
  gap: 0.25rem;
  align-items: center;
  justify-content: space-between;
  animation: ${(p) => (p.needsPulse ? pulse : null)} 3s infinite linear;
`;

const StyledDropdownButtonText = styled.span`
  flex: 1 1 auto;
  text-align: left;
`;

const StyledSvg = styled.svg`
  width: 14px;
  height: 14px;
  margin-right: 0.325rem;
`;

const StyledPath = styled.path`
  fill: ${(p) => p.theme.colorText};
`;

const StyledBetbarIcon = styled(MatIcon)`
  font-size: 14px;
  margin-right: 0.25rem;
`;

const Low6Svg = () => (
  <StyledSvg viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <StyledPath
      d="M0.42021 0.406738H10.1869L6.61567 5.76142C5.66441 6.91389 5.10174 8.33752 5.00804 9.82893L0.255859 1.46928C0.255859 1.46928 -0.417334 0.602309 0.42021 0.406738ZM13.4879 0.406738L11.0169 4.12724C11.0169 4.12724 14.8264 4.01564 16.4383 8.22812C16.6007 8.74666 16.6812 9.28736 16.677 9.83072L21.4388 1.4804C21.4388 1.4804 22.318 0.529463 21.0358 0.408533L13.4879 0.406738Z"
      fill="black"
    />
    <StyledPath
      d="M10.9062 13.2486C12.6482 13.2486 14.0604 11.8113 14.0604 10.0384C14.0604 8.26539 12.6482 6.82812 10.9062 6.82812C9.16416 6.82812 7.75195 8.26539 7.75195 10.0384C7.75195 11.8113 9.16416 13.2486 10.9062 13.2486Z"
      fill="black"
    />
    <StyledPath
      d="M8.13965 15.3647C8.97699 15.7861 9.90134 16.0055 10.8387 16.0055C11.7761 16.0055 12.7004 15.7861 13.5378 15.3647L11.23 19.5022C11.1897 19.5656 11.134 19.6177 11.0681 19.6539C11.0023 19.69 10.9284 19.7089 10.8532 19.7089C10.7781 19.7089 10.7042 19.69 10.6384 19.6539C10.5725 19.6177 10.5168 19.5656 10.4764 19.5022L8.13965 15.3647Z"
      fill="black"
    />
  </StyledSvg>
);

const Wrapper = () => {
  const dispatch = useDispatch();

  const {
    activePickem,
    activePickemList,
    activeBet,
    hadBet,
    isBetsOpen,
    isShowingMyBet,
    isActivePickemApproved,
    isActivePickemCompleted,
    isActivePickemInPlay,
    table,
    pickemList,
    hasGoalScored,
    hasGoalDisallowed,
  } = useSelector((s) => s.toolbar);

  const { hasReadBetbarHelp } = useSelector((s) => s.app);

  let footballMatch;

  if (activePickem?.id) {
    footballMatch = activePickem?.sportMatch;
  }

  const hasBet = activePickemList?.some((obj) => obj?.activeBet);

  const handleGoalUpdateAnimationEnd = () => {
    //dispatch(setToolbar({ hasGoalScored: false, hasGoalDisallowed: false }));
  };

  const handleDropdownClicked = () => {
    if (activePickemList?.length > 1) {
      dispatch(
        setToolbar({
          isBetsOpen: !isBetsOpen,
        })
      );
    }
  };

  const activeBetsCount = activePickemList?.filter((obj) => obj?.activeBet)
    .length;

  const DefaultDate = () => {
    return (
      <IconBlock
        label={
          <DateTime
            isUTC={process.env.REACT_APP_IS_UTC_DATES}
            format="dddd"
          >
            {activePickem?.start}
          </DateTime>
        }
        icon="date_range"
        value={
          <DateTime
            isUTC={process.env.REACT_APP_IS_UTC_DATES}
            format={
              activePickem?.start?.indexOf(":00:00") > -1 ? "ha" : "h:mma"
            }
          >
            {activePickem?.start}
          </DateTime>
        }
      />
    )
  };

  const DraftDate = () => {
    return (
      <IconBlock
        label={
          "Bengals"
        }
        icon="date_range"
        value={
          "NFL Draft"
        }
      />
    )
  }

  return (
    <StyledTopRow
      disabled={activePickemList?.length < 2}
      onClick={handleDropdownClicked}
    >
      <StyledEnd>
        {!activePickemList?.length ? (
          <Skeleton skeletonWidth="100px" skeletonHeight="32px" />
        ) : activePickemList?.length > 1 ? (
          <StyledDropdownButton
            needsPulse={!hasReadBetbarHelp && activePickemList?.length > 1}
          >
            {process.env.REACT_APP_ICON_BETBAR ? (
              <StyledBetbarIcon>
                {process.env.REACT_APP_ICON_BETBAR}
              </StyledBetbarIcon>
            ) : (
              <Low6Svg />
            )}
            <StyledDropdownButtonText>
              {process.env.REACT_APP_TEXT_BETBAR || "Bet Bar"}
            </StyledDropdownButtonText>
            <StyledArrowIcon isBetsOpen={isBetsOpen}>
              keyboard_arrow_down
            </StyledArrowIcon>
            {/* <StyledLabel>
                ({activeBetsCount}) {!isBetsOpen ? "More" : "Less"}

              </StyledLabel> */}
          </StyledDropdownButton>
        ) : (
          <>
            {process.env.REACT_APP_IN_DRAFT_MODE === "true" ? <DraftDate /> : <DefaultDate />}
          </>
        )}
      </StyledEnd>
      {!activePickem?.start ? (
        <Skeleton skeletonWidth="126px" skeletonHeight="32px" />
      ) : (
        <FootballMatch
          hasGoalScored={hasGoalScored}
          hasGoalDisallowed={hasGoalDisallowed}
          onAnimationEnd={handleGoalUpdateAnimationEnd}
          footballMatch={footballMatch}
          isLocked={activeBet ? true : false}
          isApproved={isActivePickemApproved}
          isCompleted={isActivePickemCompleted}
          isInPlay={isActivePickemInPlay}
        />
      )}
      <StyledEnd isRight>
        <ToolbarUpperPrize />
      </StyledEnd>
    </StyledTopRow>
  );
};

export default Wrapper;
