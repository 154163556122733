import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import history from "router/history";
import styled from "styled-components";
import {
  MatIcon,
  ClubName,
  DateTime,
  PenceToPounds,
} from "fiver-react-ui-components";

import { AffiliateBanner } from "components/shared";

import { setToolbar } from "components/layout/toolbar/actions";
import { setContest } from "components/feature/contest/actions";
import { getLeaderboard } from "components/feature/leaderboard/actions";

import ToolbarMiddleApproved from "./ToolbarMiddleApproved";
import ToolbarMiddleInPlayCompleted from "./ToolbarMiddleInPlayCompleted";

import { Row } from "./shared";

const StyledMiddle = styled.div`
  overflow: hidden;
  max-height: ${(p) => (p.isActive ? "600px" : "0")};
  transition: max-height 0.35s;
`;

const StyledRowRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1 1 auto;
  color: ${(p) => (p.isActive ? p.theme.colorDarkText : p.theme.colorText)};
`;

const Wrapper = ({ isActive, setBetsOpen }) => {
  const dispatch = useDispatch();

  const [activeRow, setActiveRow] = useState();

  const [isPlacingPaidBet, setPlacingPaidBet] = useState();

  const { showAffiliateBanners } = useSelector((s) => s.app);

  const {
    activePickemList,
    activePickem,
    activeBet,
    isActivePickemInPlay,
    isActivePickemCompleted,
  } = useSelector((s) => s.toolbar);

  const handleEnterClicked = (
    e,
    index,
    activePickem,
    isSubsequentPaidPicks
  ) => {
    window.scrollTo(0, 0);

    e.stopPropagation();

    dispatch(
      setToolbar({
        isBetsOpen: false,
        activePickem,
        activeBet: undefined,
        isActivePickemInPlay: activePickem?.status === "IN_PLAY",
        isActivePickemCompleted: activePickem?.status === "COMPLETED",
        isActivePickemApproved: activePickem?.status === "APPROVED",
      })
    );

    setPlacingPaidBet(true);
    // setActiveRow(index);

    history.push("/");

    if (isSubsequentPaidPicks) {
      dispatch(setContest({ picks: [] }));
    }
  };

  const handleCancelClicked = () => {
    setActiveRow(undefined);
    setPlacingPaidBet(false);
  };

  const handleRowClicked = (pickem) => {
    window.scrollTo(0, 0);
    // if (pickem?.activeBet) {
    dispatch(getLeaderboard(pickem, pickem?.activeBet, true, true));
    dispatch(
      setToolbar({
        activePickem: pickem,
        activeBet: pickem?.activeBet,
        isActivePickemInPlay: pickem?.status === "IN_PLAY",
        isActivePickemCompleted: pickem?.status === "COMPLETED",
        isActivePickemApproved: pickem?.status === "APPROVED",
        hadBet: pickem?.activeBet ? true : false,
      })
    );
    dispatch(setContest({ tiebreaker: pickem?.activeBet?.tieBreakerAnswer }));
    // }
  };

  const indexToShowBanner = Math.round(activePickemList?.length / 2);

  return (
    <StyledMiddle isActive={isActive}>
      {activePickemList?.map((pickem, index) => {
        const isActive =
          activePickem?.id === pickem?.id &&
          activePickem?.activeBet?.betId === pickem?.activeBet?.betId;

        return (
          <>
            {index === 0 && showAffiliateBanners ? <AffiliateBanner /> : null}
            <Row isActive={isActive} onClick={() => handleRowClicked(pickem)}>
              {pickem?.status !== "APPROVED" ? (
                <ToolbarMiddleInPlayCompleted
                  pickem={pickem}
                  isActive={isActive}
                  handleRowClicked={handleRowClicked}
                />
              ) : (
                <ToolbarMiddleApproved
                  pickem={pickem}
                  isActive={isActive}
                  handleEnterClicked={handleEnterClicked}
                  handleRowClicked={handleRowClicked}
                  index={index}
                />
              )}
            </Row>
          </>
        );
      })}
    </StyledMiddle>
  );
};

export default Wrapper;
