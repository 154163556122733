import React, { useState, useEffect } from "react";
import styled from "styled-components";

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem 0;
  border-bottom: 2px solid ${(props) => props.theme.colorBorder};
`;

const StyledNumber = styled.div`
  flex: 1 0 30px;
  width: 30px;
  height: 30px;
  max-width: 30px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  border-radius: 50%;
  color: ${(props) => props.theme.colorAccentText};
  background: ${(props) => props.theme.colorAccent};
`;

const StyledText = styled.div`
  flex: 1 1 auto;
  margin-left: 0.75rem;
  line-height: 1.35;
  font-size: 0.75rem;
  font-weight: 400;
`;

const ContestBannerHelpList = (props) => {
  const [text, setText] = useState([]);

  useEffect(() => {
    const textArray = [
      `Make your ${process.env.REACT_APP_TEXT_PICKS || "Picks"} for each question below`,
    ];

    if (process.env.REACT_APP_HAS_TIEBREAK === "true") {
      textArray.push(
        `Don’t forget to submit your ${
          process.env.REACT_APP_TIEBREAKER_LABEL || "Bonus Question"
        } Answer`
      );
    }

    textArray.push(
      `Click ${process.env.REACT_APP_TEXT_PLACE_BET || "Place Bet"} to submit
  your entry into the contest`
    );

    if (process.env.REACT_APP_IN_DRAFT_MODE === "true") {
      textArray.push("Follow the 2022 draft live with our real-time leaderboard");
    } else {
      textArray.push("Follow the game live with our real-time leaderboard");
    }

    setText(textArray);
  }, []);

  return (
    <div style={{ marginBottom: "1rem" }}>
      {text.map((t, i) => (
        <StyledRow>
          <StyledNumber>{i + 1}</StyledNumber>
          <StyledText>{t}</StyledText>
        </StyledRow>
      ))}
    </div>
  );
};

export default ContestBannerHelpList;
