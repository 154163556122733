import axios from "axios";
import { handleError, logXtremePush } from "utils";
import { toast } from "react-toastify";
import { setToolbar } from "components/layout/toolbar/actions";
import { setApp } from "../app/actions";

//import mockContest from "mocks/mockContestPosh.json";

export const SET_CONTEST = "contest:SET_CONTEST";
export const GET_PICKEMS = "contest:GET_PICKEMS";
export const GET_PICKEMS_BETS = "contest:GET_PICKEMS_BETS";
export const SET_PICKS = "contest:SET_PICKS";
export const SET_IS_ALL_SELECTED = "contest:SET_IS_ALL_SELECTED";

export const setContest = (contest) => (dispatch) => {
  dispatch({
    type: SET_CONTEST,
    payload: contest,
  });
};

export const setPicks = (picks) => (dispatch, getState) => {
  let xtremepushData = {};

  getState().toolbar.activePickem?.questions?.map((obj, index) => {
    xtremepushData[`Question${index + 1}Question`] = obj?.label;
    xtremepushData[`Question${index + 1}Answer`] = picks
      ? obj?.options?.find((opt) => opt.id === picks[obj.id])?.label
      : "";

    return null;
  });

  dispatch({
    type: SET_CONTEST,
    payload: {
      xtremepushData,
    },
  });

  dispatch({
    type: SET_PICKS,
    payload: picks,
  });
};

export const setAllSelected = (value) => (dispatch) => {
  dispatch({
    type: SET_IS_ALL_SELECTED,
    payload: {
      isAllSelected: value,
    },
  });
};

export const placeBet = (
  activePickemId,
  selections,
  activePickemName,
  tieBreakerAnswer
) => (dispatch, getState) => {
  dispatch({
    type: SET_CONTEST,
    payload: { isPlacingBet: true },
  });

  const activePickemList = getState().toolbar.activePickemList.slice();
  const pickem = activePickemList.find((obj) => obj.id === activePickemId);

  const pickemHasOtherBets = activePickemList?.some(
    (obj) => obj.id === activePickemId && obj.activeBet
  );

  axios
    .post(`/pickems/${activePickemId}/bets`, { selections, tieBreakerAnswer })
    .then(({ data }) => {
      dispatch(
        setContest({
          isPlacingBet: false,
        })
      );

      const activeBet = {
        betId: data?.data?.id,
        ...data?.data,
        tieBreakerAnswer,
        selections,
      };

      // decide whether to add extra pickem to the list
      if (pickemHasOtherBets) {
        const originalPickem = activePickemList?.find(
          (obj) => obj.id === activePickemId
        );

        const myNewPickem = {
          ...originalPickem,
          activeBet: activeBet,
        };

        myNewPickem.usersBets.push(activeBet);

        activePickemList.unshift(myNewPickem);

        myNewPickem.betsCount++;

        dispatch(
          setToolbar({
            activePickem: myNewPickem,
          })
        );
      } else {
        const usersBets = [activeBet];
        pickem.usersBets = usersBets;

        pickem.activeBet = activeBet;
      }

      dispatch(
        setToolbar({
          hadBet: true,
          activeBet,
          activePickemList,
        })
      );

      if (process.env.REACT_APP_TEXT_PICKS) {
        toast.success(
          `Your ${process.env.REACT_APP_TEXT_PICKS.toLowerCase()} have been submitted! Check back during and after the game to see how you did!`
        );
      } else {
        toast.success("Picks locked In!");
      }

      if (process.env.REACT_APP_AFFILIATE_POPUP_TYPE === "Submission") {
        dispatch(
          setApp({
            showSubmissionPopup: true,
          })
        );
      }

      const xtremepushData = getState().contest.xtremepushData;

      // 
      /// Commented to potentially improve this when we've got an easier way of testing exterme push modifications
      //
      // logXtremePush(
      //   "tag",
      //   pickem?.stake > 0 ? "MyPicks.PlaceBet" : "MyPicks.PlaceFreeBet",
      //   JSON.stringify({
      //     AppName: process.env.REACT_APP_TITLE,
      //     ContestName: activePickemName,
      //     ...xtremepushData,
      //   })
      // );

      // logXtremePush(
      //   "event",
      //   pickem?.stake > 0 ? "MyPicks.PlaceBet" : "MyPicks.PlaceFreeBet",
      //   JSON.stringify({
      //     AppName: process.env.REACT_APP_TITLE,
      //     ContestName: activePickemName,
      //     ...xtremepushData,
      //   })
      // );

      window.xtremepush(
        "tag",
        pickem?.stake > 0 ? "MyPicks.PlaceBet" : "MyPicks.PlaceFreeBet",
        JSON.stringify({
          AppName: process.env.REACT_APP_TITLE,
          ContestName: activePickemName,
          UserPicks: {
            ...xtremepushData
          }
        })
      );

      window.xtremepush(
        "event",
        pickem?.stake > 0 ? "MyPicks.PlaceBet" : "MyPicks.PlaceFreeBet",
        JSON.stringify({
          AppName: process.env.REACT_APP_TITLE,
          ContestName: activePickemName,
          UserPicks: {
            ...xtremepushData
          }
        })
      );
    })
    .catch(({ response }) => {
      dispatch({
        type: SET_CONTEST,
        payload: { isPlacingBet: undefined },
      });

      if (response?.data?.code === "mobileNumberIsNotVerified") {
        toast.error("Verify Your Phone First");
      } else if (response?.data?.code === "userIsCoolingOff") {
        toast.error("You're still cooling off");
      } else if (response?.data?.code === "identityReferred") {
        toast.error("Identity not verifed yet");
      } else if (response?.data?.code === "insufficientFunds") {
        dispatch(
          setContest({
            picks: undefined,
          })
        );
        toast.error("Insuffient Funds");
      } else if (response?.data?.code) {
        toast.error(`Error: ${response?.data?.code}`);
      } else {
        dispatch(
          setContest({
            picks: undefined,
          })
        );
        toast.error(
          `Duplicate ${process.REACT_APP_HIDE_GAMBLING_REFERENCES === "true"
            ? process.env.REACT_APP_TEXT_PICKS || "picks"
            : "bet"
          } placed`
        );
      }

      handleError(response);
    });
};
