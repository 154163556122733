import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { ThemeProvider } from "styled-components";
import { theme } from "../theme";
import { Router, Switch, useLocation } from "react-router-dom";
import history from "../router/history";
import { ToastContainer, cssTransition } from "react-toastify";

import AppRoute from "./AppRoute";

import { MatIcon } from "components/shared";
import GlobalStyle from "./GlobalStyle";

import Leaderboard from "./feature/leaderboard/Leaderboard";
import Wallet from "./feature/wallet/Wallet";
import WalletCardResult from "./feature/wallet/WalletCardResult";
import Splash from "./layout/splash/Splash";
import NovaPage from "./feature/app/NovaPage";

import GoogleTagManager from "./GoogleTagManager";
// import Branch from "./Branch";

import Profile from "./feature/profile/Profile";
import Login from "./feature/auth/login/Login";
import Signup from "components/feature/auth/Signup";
import ForgotPassword from "./feature/auth/ForgotPassword";
import ChangePassword from "./feature/auth/ChangePassword";
import ChangePin from "./feature/auth/ChangePin";
import ConfirmPhoneNumber from "./feature/auth/ConfirmPhoneNumber";
import TakeBreak from "./feature/auth/TakeBreak";
import SelfExclusion from "./feature/auth/SelfExclusion";
import Contest from "./feature/contest/Contest";

import NotFound from "./feature/app/404";

import BlenheimConsent from "./feature/auth/BlenheimConsent";
import ConsentPopup from "./feature/auth/ConsentPopup";

import { loadAppStuff, setScrolledDown } from "components/feature/app/actions";

import { useGetLocation, useTenantDetails } from "hooks";

import "react-toastify/dist/ReactToastify.css";

const StyledContainer = styled.div`
  width: 100%;

  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }
`;

const StyledCloseButton = styled(MatIcon)`
  position: absolute;
  top: 50%;
  right: 1.5rem;
  transform: translateY(-50%);
`;

const slideTransition = cssTransition({
  enter: "slideUp",
  exit: "slideDown",
});

const slideNewTransition = cssTransition({
  enter: "slideUpNew",
  exit: "slideDownNew",
});

const Root = (props) => {
  const dispatch = useDispatch();

  useGetLocation();

  useTenantDetails();

  const { isAllSelected } = useSelector((state) => state.contest);

  const { isScrolledDown } = useSelector((state) => state.app);

  const [state, setState] = useState({
    theme: theme,
  });

  useEffect(() => {
    const unload = async () => {
      await window.xtremepush(
        "tag",
        "app_close",
        JSON.stringify({
          AppName: process.env.REACT_APP_TITLE,
        })
      );
      return undefined;
    };
    window.addEventListener("beforeunload", unload);
    return () => {
      window.removeEventListener("beforeunload", unload);
    };
  }, []);

  useEffect(() => {
    setState({
      ...state,
      theme: {
        ...state.theme,
        fontFamily: process.env.REACT_APP_FONT_FAMILY || theme.fontFamily,
        inputFontFamily:
          process.env.REACT_APP_INPUT_FONT_FAMILY ||
          process.env.REACT_APP_FONT_FAMILY ||
          theme.fontFamily,
        colorPrimary: process.env.REACT_APP_COLOR_PRIMARY,
        colorPrimaryText: process.env.REACT_APP_COLOR_PRIMARY_TEXT || "white",
        colorPrimaryGradient: process.env.REACT_APP_COLOR_PRIMARY_GRADIENT,
        colorAccent:
          process.env.REACT_APP_COLOR_ACCENT ||
          process.env.REACT_APP_COLOR_PRIMARY,
        colorAccentText: process.env.REACT_APP_COLOR_ACCENT_TEXT || "white",
        colorDark: process.env.REACT_APP_COLOR_DARK || "#1f1f1f",
        colorDarkText: process.env.REACT_APP_COLOR_DARK_TEXT || "white",
        colorBackground:
          process.env.REACT_APP_COLOR_BACKGROUND ||
          process.env.REACT_APP_COLOR_PRIMARY,
        colorSuccess: process.env.REACT_APP_COLOR_SUCCESS,
        colorSuccessText: process.env.REACT_APP_COLOR_SUCCESS_TEXT || "white",
        colorDanger: process.env.REACT_APP_COLOR_DANGER || theme.colorDanger,
        colorDangerText: process.env.REACT_APP_COLOR_DANGER_TEXT || "white",
        colorBurgerTitleBackground:
          process.env.REACT_APP_COLOR_BURGER_HEADER ||
          process.env.REACT_APP_COLOR_DARK ||
          "#1f1f1f",
        colorBurgerTitleText:
          process.env.REACT_APP_COLOR_BURGER_TEXT || theme.colorDarkText,
      },
    });

    const listener = (e) => {
      const { scrollY } = window;

      if (!isScrolledDown && scrollY > 1) {
        dispatch(setScrolledDown(true));
      }

      if (isScrolledDown && scrollY < 1) {
        dispatch(setScrolledDown(false));
      }
    };

    window.addEventListener("scroll", listener);
    return () => {
      window.removeEventListener("scroll", listener);
    };
  }, [setState, isScrolledDown]);

  useEffect(() => {
    dispatch(loadAppStuff());
  }, []);

  return (
    <Router history={history}>
      <ThemeProvider theme={state.theme}>
        <GlobalStyle />
        <GoogleTagManager />
        <ToastContainer
          closeButton={<StyledCloseButton size="30px">close</StyledCloseButton>}
          autoClose={3000}
          limit={1}
          position="bottom-center"
          hideProgressBar
        />

        <Splash />

        {process.env.REACT_APP_IS_BLENHEIM === "true" && <BlenheimConsent />}
        {process.env.REACT_APP_HAS_CONSENT_POPUP === "true" && <ConsentPopup />}

        <StyledContainer>
          <Switch>
            <AppRoute
              path="/"
              topbarBurgerMenu
              topbarTitle={process.env.REACT_APP_HIDE_TOPBAR_LOGO === "true" && "Picks"}
              topbarTitleLogo={process.env.REACT_APP_HIDE_TOPBAR_LOGO !== "true"}
              bottombar
              hasToolbar
              hasAffiliateLogo
              hasAffiliatePopup
              exact
              component={Contest}
              needsPaddingTop
            />

            {/* <AppRoute
              noPadding
              path="/"
              isLandingPage
              hideLow6Footer
              hasPrimaryBackground
              exact
              component={Contest}
            /> */}

            <AppRoute
              hideLow6Footer
              needsPaddingTop
              topbarBackButton
              topbarTitle="Login"
              path="/login"
              exact
              component={Login}
            />
            {process.env.REACT_APP_IS_SIGNUP_DISABLED !== "true" && (
              <AppRoute
                hideLow6Footer
                topbarBackButton
                topbarTitle="Signup"
                path="/signup"
                exact
                component={Signup}
              />
            )}

            <AppRoute
              needsPaddingTop
              topbarBackButton
              topbarTitle="Forgot Password"
              path="/forgot"
              exact
              component={ForgotPassword}
            />
            <AppRoute
              isAuthRequired
              needsPaddingTop
              topbarBackButton
              topbarTitle="Change Password"
              path="/change-password"
              exact
              bottombar
              component={ChangePassword}
            />
            <AppRoute
              isAuthRequired
              needsPaddingTop
              topbarBackButton
              topbarTitle="Change Pin"
              path="/change-pin"
              exact
              bottombar
              component={ChangePin}
            />
            <AppRoute
              isAuthRequired
              needsPaddingTop
              topbarBackButton
              topbarTitle="Verify Phone Number"
              path="/confirm-phone"
              exact
              component={ConfirmPhoneNumber}
            />
            <AppRoute
              isAuthRequired
              needsPaddingTop
              topbarBackButton
              topbarTitle="Take a break"
              path="/take-break"
              exact
              bottombar
              component={TakeBreak}
            />
            <AppRoute
              isAuthRequired
              needsPaddingTop
              topbarBackButton
              topbarTitle="Self Exclusion"
              path="/self-exclusion"
              exact
              bottombar
              component={SelfExclusion}
            />
            <AppRoute
              path="/leaderboard"
              isAuthRequired
              topbarBurgerMenu
              topbarTitle={
                process.env.REACT_APP_HIDE_TOPBAR_LOGO === "true" &&
                "Leaderboard"
              }
              topbarTitleLogo={
                process.env.REACT_APP_HIDE_TOPBAR_LOGO !== "true"
              }
              bottombar
              matchData
              exact
              hasToolbar
              hasAffiliateLogo
              needsPaddingTop={process.env.REACT_APP_AFFILIATE_LOGO}
              isLeaderboard
              component={Leaderboard}
            />
            <AppRoute
              path="/wallet"
              isAuthRequired
              topbarBurgerMenu
              topbarTitle={
                process.env.REACT_APP_HIDE_TOPBAR_LOGO === "true" && "Wallet"
              }
              topbarTitleLogo={
                process.env.REACT_APP_HIDE_TOPBAR_LOGO !== "true"
              }
              bottombar
              exact
              component={Wallet}
            />
            <AppRoute
              isAuthRequired
              path="/card-result"
              component={WalletCardResult}
            />
            <AppRoute
              isAuthRequired
              path="/profile"
              topbarBurgerMenu
              topbarTitle={
                process.env.REACT_APP_HIDE_TOPBAR_LOGO === "true" && "Profile"
              }
              topbarTitleLogo={
                process.env.REACT_APP_HIDE_TOPBAR_LOGO !== "true"
              }
              bottombar
              hasAffiliateLogo
              exact
              component={Profile}
            />
            <AppRoute
              path="/content/:id"
              hideLegalFooter
              hideLow6Footer
              component={NovaPage}
            />
            <AppRoute topbarBackButton topbarTitle="404" component={NotFound} />
          </Switch>
        </StyledContainer>
      </ThemeProvider>
    </Router>
  );
};

export default Root;
